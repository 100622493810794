import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

function Dashboard() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    let navigate = useNavigate();
    const userPermission = JSON.parse(user.permission);
    useEffect(() => {
        if(user.role_name !== "admin" && !userPermission.home){
            dispatch({ type: 'LOGOUT', payload: null });
            localStorage.removeItem(process.env.REACT_APP_SECRET);
            navigate("/");
        }
    }, []);

    return (
        <>
            <section className='dashboard-content'>
                <Row>
                    <Col>
                        <div className='content-box'>
                            <div className='box-content'>
                                <p className='box-info'>New Orders</p>
                                <h1>52</h1>
                                <div className='box-icon'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_76_103)">
                                            <path d="M5 7C5 8.06087 5.42143 9.07828 6.17157 9.82843C6.92172 10.5786 7.93913 11 9 11C10.0609 11 11.0783 10.5786 11.8284 9.82843C12.5786 9.07828 13 8.06087 13 7C13 5.93913 12.5786 4.92172 11.8284 4.17157C11.0783 3.42143 10.0609 3 9 3C7.93913 3 6.92172 3.42143 6.17157 4.17157C5.42143 4.92172 5 5.93913 5 7Z" stroke="#F58A0B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 21V19C3 17.9391 3.42143 16.9217 4.17157 16.1716C4.92172 15.4214 5.93913 15 7 15H11C12.0609 15 13.0783 15.4214 13.8284 16.1716C14.5786 16.9217 15 17.9391 15 19V21" stroke="#F58A0B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="#F58A0B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M21 20.9999V18.9999C20.9949 18.1171 20.6979 17.2607 20.1553 16.5643C19.6126 15.8679 18.8548 15.3706 18 15.1499" stroke="#F58A0B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_76_103">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <hr />
                                <div className='box-footer'>
                                    <div className='counter theme-success theme-text-success'>
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_76_23)">
                                                <path d="M6.66666 5.16667L9.33332 2.5L12 5.16667" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.33334 2.5V5.42933C9.33339 6.16879 9.15087 6.89682 8.80198 7.54878C8.45308 8.20075 7.94861 8.75649 7.33334 9.16667C6.71808 9.57684 6.21361 10.1326 5.86471 10.7845C5.51582 11.4365 5.33329 12.1645 5.33334 12.904V14.5" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_76_23">
                                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span>+15%</span>
                                    </div>
                                    <p>Form the last month</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='content-box'>
                            <div className='box-content'>
                                <p className='box-info'>Active Customers</p>
                                <h1>52</h1>
                                <div className='box-icon customer'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_76_109)">
                                            <path d="M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z" stroke="#4A58EC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14" stroke="#4A58EC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15 19L17 21L21 17" stroke="#4A58EC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_76_109">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <hr />
                                <div className='box-footer'>
                                    <div className='counter theme-danger theme-text-danager'>
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_76_23)">
                                                <path d="M6.66666 5.16667L9.33332 2.5L12 5.16667" stroke="#FF5636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.33334 2.5V5.42933C9.33339 6.16879 9.15087 6.89682 8.80198 7.54878C8.45308 8.20075 7.94861 8.75649 7.33334 9.16667C6.71808 9.57684 6.21361 10.1326 5.86471 10.7845C5.51582 11.4365 5.33329 12.1645 5.33334 12.904V14.5" stroke="#FF5636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_76_23">
                                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span>+15%</span>
                                    </div>
                                    <p>Form the last month</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='content-box'>
                            <div className='box-content'>
                                <p className='box-info'>Total Profile</p>
                                <h1>52</h1>
                                <div className='box-icon profile'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_76_114)">
                                            <path d="M9 14L15 8" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.5 9C9.77614 9 10 8.77614 10 8.5C10 8.22386 9.77614 8 9.5 8C9.22386 8 9 8.22386 9 8.5C9 8.77614 9.22386 9 9.5 9Z" fill="#0EB17F" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M14.5 14C14.7761 14 15 13.7761 15 13.5C15 13.2239 14.7761 13 14.5 13C14.2239 13 14 13.2239 14 13.5C14 13.7761 14.2239 14 14.5 14Z" fill="#0EB17F" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_76_114">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <hr />
                                <div className='box-footer'>
                                    <div className='counter theme-success theme-text-success'>
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_76_23)">
                                                <path d="M6.66666 5.16667L9.33332 2.5L12 5.16667" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.33334 2.5V5.42933C9.33339 6.16879 9.15087 6.89682 8.80198 7.54878C8.45308 8.20075 7.94861 8.75649 7.33334 9.16667C6.71808 9.57684 6.21361 10.1326 5.86471 10.7845C5.51582 11.4365 5.33329 12.1645 5.33334 12.904V14.5" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_76_23">
                                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span>+15%</span>
                                    </div>
                                    <p>Form the last month</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='content-box'>
                            <div className='box-content'>
                                <p className='box-info'>Total Expemse</p>
                                <h1>52</h1>
                                <div className='box-icon expemes'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_76_120)">
                                            <path d="M4 7L4.867 19.143C4.90322 19.6483 5.12982 20.121 5.50104 20.4656C5.87226 20.8103 6.36044 21.0013 6.867 21H17.143C17.6496 21.0013 18.1377 20.8103 18.509 20.4656C18.8802 20.121 19.1068 19.6483 19.143 19.143L20.01 7H4.01H4Z" stroke="#7E42FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.5 7C8.5 5.347 10 3 12 3C14 3 15.5 5.347 15.5 7" stroke="#7E42FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.5 17C9.913 17.462 10.5 18 12 18C13.5 18 14.5 17.103 14.5 16C14.5 14.897 13.5 14.5 12 14C10.5 13.5 10 12.53 10 12C10 10.896 11 10 12 10C13 10 13.5 10 14.5 11" stroke="#7E42FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_76_120">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <hr />
                                <div className='box-footer'>
                                    <div className='counter theme-success theme-text-success'>
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_76_23)">
                                                <path d="M6.66666 5.16667L9.33332 2.5L12 5.16667" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.33334 2.5V5.42933C9.33339 6.16879 9.15087 6.89682 8.80198 7.54878C8.45308 8.20075 7.94861 8.75649 7.33334 9.16667C6.71808 9.57684 6.21361 10.1326 5.86471 10.7845C5.51582 11.4365 5.33329 12.1645 5.33334 12.904V14.5" stroke="#0EB17F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_76_23">
                                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span className=''>+15%</span>
                                    </div>
                                    <p>Form the last month</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/* <Col>
                        <div className='content-box bg-danger'>
                            <h1>150</h1>
                            <p className='box-info'>Return Oreders</p>
                            <div className='box-icon'>
                                <FaCartArrowDown size={50} color='#0000005c' />
                            </div>
                            <Link className='content-box-btn'>More Info <FaCircleArrowRight /> </Link>
                        </div>
                    </Col>
                    <Col>
                        <div className='content-box bg-warning'>
                            <h1>10%</h1>
                            <p className='box-info'>Sales</p>
                            <div className='box-icon'>
                                <FaChartColumn size={50} color='#0000005c' />
                            </div>
                            <Link className='content-box-btn'>More Info <FaCircleArrowRight size={18} /> </Link>
                        </div>
                    </Col>
                    <Col>
                        <div className='content-box bg-info'>
                            <h1>80%</h1>
                            <p className='box-info'>Inventory</p>
                            <div className='box-icon'>
                                <FaBoxes size={50} color='#0000005c' />
                            </div>
                            <Link className='content-box-btn'>More Info <FaCircleArrowRight size={18} /> </Link>
                        </div>
                    </Col> */}
                </Row>
            </section>
        </>
    );
}

export default Dashboard;
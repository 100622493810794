import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import FileSaver from "file-saver";
import { Modal, NavDropdown } from 'react-bootstrap';
import DatePicker from "react-datepicker";


function Attendance() {
    const user = useSelector((state) => state.user.user);
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isLoding, setIsLoding] = useState(true);
    const [serachRes, setserachRes] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [attendanceData, setAttendanceData] = useState([]);
    const [actionMenu, setActionMenu] = useState({ id: null, status: 'd-none' });
    const [tasknotes, setTaskNotes] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        getAllAttendance(currentPage);
    }, [serachRes, currentPage]);

    async function getAllAttendance(page) {
        !isLoding && setIsLoding(true);

        let attendanceUrl = (sDate && eDate)
            ? `${process.env.REACT_APP_BASE_URL}all-attendance?emp_id=${params.get("emp_id")}&sDate=${sDate}&eDate=${eDate}&pageCount=${page}`
            : process.env.REACT_APP_BASE_URL + `all-attendance?emp_id=${params.get("emp_id")}&pageCount=${page}`
        await axios.get(attendanceUrl, {
            headers: {
                'x-access-token': localStorage.getItem(process.env.REACT_APP_SECRET)
            }
        }).then((response) => {
            let attendanceDataCopy;
            if (response.status === 200) {
                attendanceDataCopy = response.data.attendance;
                setAttendanceData(attendanceDataCopy);
            }
            setIsLoding(false);
        }).catch((err) => {
            console.log(err);
            console.log(err.message);
        });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    let sDate, eDate;
    const SerachDate = async () => {
        sDate = startDate;
        eDate = endDate;
        getAllAttendance()
    }

    const ShowActions = (ActionId) => {
        if (actionMenu.status == 'd-none') {
            setActionMenu({ id: ActionId, status: 'd-block' });
        } else {
            setActionMenu({ id: ActionId, status: 'd-none' });
            if (ActionId != actionMenu.id) {
                setActionMenu({ id: ActionId, status: 'd-block' })
            }
        }
    }

    const showTaskNotes = () => {
        setTaskNotes("");
        setShow(true);
    }

    const showMyTask = async (id) => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}all-attendance?my_task=${id}`, {
            headers: {
                'x-access-token': localStorage.getItem(process.env.REACT_APP_SECRET)
            }
        }).then((response) => {
            setActionMenu({ id: id, status: 'd-none' })
            setTaskNotes(response.data.attendance.todo);
            setShow(true);
        }).catch((err) => {
            console.log(err);
        });
    }

    // const exportAttendance = async () => {
    //     await axios.get(`${process.env.REACT_APP_BASE_URL}export-attendance`)
    //         .then((response) => {
    //             // console.log(Blob());
    //             const url =  new Blob(response.data);
    //             return FileSaver.saveAs(url, "users.xlsx");
    //         })
    // }

    return (
        <>
            <section className='content'>
                <div className='content-header'>
                    <p className='text-theme'>All Attendance</p>
                    <div className='content-header-right'>
                        <div className="search-date">
                            <DatePicker
                                showIcon
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="From Date"
                            />&nbsp;&nbsp;&nbsp;
                            <DatePicker
                                showIcon
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="To Date"
                            />&nbsp;&nbsp;&nbsp;
                            <button className="theme-btn-primary py-0" onClick={SerachDate}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z"></path>
                                </svg>
                            </button>
                        </div>
                        {/* <div className='content-header-search'>
                            <span className='search-icon'>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.9999 21.4999L16.6499 17.1499" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                            <input type="search" id="" className='' value={serachRes} placeholder='App name searching...' onChange={(search) => setserachRes(search.target.value)} />
                        </div> */}
                        {/* <button className='theme-btn-primary' onClick={exportAttendance}>Export Attendance</button> */}
                    </div>
                </div>
                <table className='table-list'>
                    <thead>
                        <tr>
                            <th className="attend-filed">Date</th>
                            <th className="attend-filed">Check In</th>
                            <th className="attend-filed">Check Out</th>
                            <th className="attend-filed">Active Hours</th>
                            <th>Todo</th>
                            <th className='table-action'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoding ? attendanceData.length ? attendanceData.map((attendval, appkey) => {
                            var checkIn = new Date(attendval.check_in);
                            var checkOut = new Date(attendval.check_out);
                            var date = new Date(attendval.date);
                            return (
                                <tr key={appkey}>
                                    <td>{date.toDateString()}</td>
                                    <td>{checkIn.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}</td>
                                    <td>{attendval.check_out ? checkOut.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }) : "--"}</td>
                                    <td>{attendval.total_hours ? attendval.total_hours : '--'}</td>
                                    <td className='text-center'>{attendval.todo ? attendval.todo : '--'}</td>
                                    <td className='text-center'>
                                        {/* <div className='table-actions-menu'>
                                            <svg onClick={() => ShowActions(appkey)} className='table-more' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                <g clipPath="url(#clip0_86_9073)">
                                                    <path d="M11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12Z" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11 19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19C13 18.7348 12.8946 18.4804 12.7071 18.2929C12.5196 18.1054 12.2652 18 12 18C11.7348 18 11.4804 18.1054 11.2929 18.2929C11.1054 18.4804 11 18.7348 11 19Z" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11 5C11 5.26522 11.1054 5.51957 11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5Z" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_86_9073">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <ul className={`table-action-menu ${actionMenu.id == appkey ? actionMenu.status : 'd-none'}`}>
                                                <li className='action-btn' onClick={() => showMyTask(attendval._id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#575A74" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
                                                        <path d="M9 7l6 0"></path>
                                                        <path d="M9 11l6 0"></path>
                                                        <path d="M9 15l4 0"></path>
                                                    </svg>
                                                    <div className='action-text'>
                                                        Task
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> */}
                                        <NavDropdown title="Action">
                                            <NavDropdown.Item onClick={() => showMyTask(attendval._id)}>
                                                <div className='action-btn'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#575A74" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
                                                        <path d="M9 7l6 0"></path>
                                                        <path d="M9 11l6 0"></path>
                                                        <path d="M9 15l4 0"></path>
                                                    </svg>
                                                    <div className='action-text'>
                                                        Task
                                                    </div>
                                                </div>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </td>
                                </tr>
                            )
                        }) : <tr>
                            <td colSpan={6} className='text-center theme-text-danager'>Not Found</td>
                        </tr> : <tr>
                            <td colSpan={6} className='text-center theme-text-danager position-relative'><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></td>
                        </tr>}
                    </tbody>
                </table>
                {dataCount > 10 && <div className='footer-pagination'>
                    <ul className='paginations d-flex justify-content-end gap-1'>
                        {
                            Array.apply(null, Array(Math.ceil(dataCount / 10))).map((val, key) => {
                                return <li key={key} className={`pages-item ${currentPage === (key + 1) ? 'active' : ''}`} onClick={() => handlePageChange(key + 1)}>{key + 1}</li>
                            })
                        }
                    </ul>
                </div>}

            </section>

            <Modal
                show={show}
                size="lg"
                onHide={() => setShow(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Task Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post">
                        <div className="form-group m-0">
                            <textarea
                                placeholder="Task Note..."
                                name="app_note"
                                rows={15}
                                onChange={(task) => setTaskNotes(task.target.value)}
                                value={tasknotes}
                                disabled
                            />
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Attendance;

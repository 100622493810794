import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getApi } from "../helpers/api";
import Sidebar from "./sidebar";
import Navbar from "./navbar";

function Intro({ children }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    globalUser();
  }, []);

  async function globalUser() {
    await getApi('dashboard', localStorage.getItem(process.env.REACT_APP_SECRET))
      .then((res) => {
        dispatch({ type: 'LOGIN', payload: res.data });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate('/');
        }
      });
  }

  return (
    <>
      {user && <div className="wrapper">
        <Navbar />
        <Sidebar />
        <div className='content-wrapper'>
          {children}
        </div>
      </div>}
    </>
  );
}

export default Intro;
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { getApi, postApi } from "../helpers/api";
import "./auth.css"

function SignIn() {

    let navigate = useNavigate();
    const [allRoel, setAllRoel] = useState([]);

    const successNotify = (messageSucc) => toast.success(messageSucc, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const errorNotify = (messageErr) => toast.error(messageErr, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    useEffect(() => {
        getRole();
    }, []);

    async function getRole() {
        await getApi('role')
            .then((response) => {
                console.log(response.data);
                setAllRoel(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const [initialValues, setInitialValues] = useState({
        "fullname": '',
        "email": '',
        "role_id": '',
        "password": '',
        "mobile_no": ''
    })

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = yup.object({
        fullname: yup.string().required("Name is required."),
        email: yup.string()
            .required("Email is required.")
            .matches(
                /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                "Please enter valid email address"
            ),
        role_id: yup.string().required("Role is required."),
        password: yup.string().min(8, "should be 8 chars minimum.").required("Password is required."),
        mobile_no: yup.string().matches(phoneRegExp, 'Mobile number is not valid').required("Mobile number required.")
    })

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await postApi(`sign-up`, values)
                .then((res) => {
                    successNotify(res.data.message);
                    navigate("/");
                })
                .catch((error) => {
                    errorNotify(error.response.data.message);
                });
        }
    });

    return (
        <>
            <ToastContainer />
            <div className="authentication">
                <div className="left-box">
                    <div className="form-box">
                        <div className="auth-head">
                            <h3>Hi, Welcome Back!</h3>
                            <p>Platform where user will able to keep their money.</p>
                        </div>
                        <form onSubmit={handleSubmit} autoComplete="Off" className="auth-form">
                            <div className="form-group">
                                <label htmlFor="">Full Name</label>
                                <input
                                    type="text"
                                    className=""
                                    placeholder='Enter Full Name'
                                    name="fullname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                />
                                {errors.fullname && touched.fullname ? (
                                    <span className="required-lable">{errors.fullname} </span>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Email</label>
                                <input
                                    type="text"
                                    className=""
                                    placeholder='Enter Email'
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                                {errors.email && touched.email ? (
                                    <span className="required-lable">{errors.email} </span>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Password</label>
                                <input
                                    type="password"
                                    className=""
                                    placeholder='Enter Password'
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                                {errors.password && touched.password ? (
                                    <span className="required-lable">{errors.password} </span>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Our Role</label>
                                <select className="auth-select" onChange={(role) => setFieldValue('role_id', role.target.value)} onBlur={handleBlur}>
                                    <option>Select Role</option>
                                    {
                                        allRoel.map((roleval, rolekey) => {
                                            return <option key={rolekey} value={roleval.id}>{roleval.role_name}</option>
                                        })
                                    }
                                </select>
                                {errors.role_id && touched.role_id ? (
                                    <span className="required-lable">{errors.role_id} </span>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Mobile number</label>
                                <input
                                    type="text"
                                    className=""
                                    placeholder='Enter mobile number'
                                    name="mobile_no"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.mobile_no}
                                />
                                {errors.mobile_no && touched.mobile_no ? (
                                    <span className="required-lable">{errors.mobile_no} </span>
                                ) : null}
                            </div>
                            <button type="submit" className="form-submit">Sign In</button>
                            <p className="form-link">I Have an account?&nbsp;<Link to="/">Sign In</Link></p>
                        </form>
                    </div>
                </div>
                <div className="right-box">
                    <img src="images/auth-signin.svg" alt="" />
                </div>
            </div>
        </>
    );
}

export default SignIn;
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import Icon from "react-multi-date-picker/components/icon";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./layout.css"

function Navbar() {
    let navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [value, setValue] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getLeave();
    }, []);

    const getLeave = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}leave`, {
            headers: {
                'x-access-token': localStorage.getItem(process.env.REACT_APP_SECRET)
            }
        })
            .then(({ data }) => {
                setValue(data.data.leave);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const SignOut = () => {
        dispatch({ type: 'LOGOUT', payload: null });
        localStorage.removeItem(process.env.REACT_APP_SECRET);
        navigate("/");
    }

    return (
        <nav className='main-header'>
            <ul className='nav-bar'>
                <p className='nav-head'>Hello, {user.fullname}</p>
            </ul>
            <ul className='nav-bar'>
                <li>
                    <DatePicker
                        className="custom-calendar"
                        render={<Icon />}
                        value={value}
                    />
                </li>
                <div className='user-panal'
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <div className='user-avatar' style={(!user.user_image) ? { backgroundColor: '#010226' } : { backgroundColor: "unset" }}>
                        {(user && user.user_image) ?
                            <img src={`${process.env.REACT_APP_BASE_URL}uploads/userimages/${user.user_image}`} alt='user image' /> : <p className='user-name-avatar'>{user.fullname?.charAt(0)}</p>
                        }
                    </div>
                    <p className='user-info'>{user && user.fullname}</p> &nbsp;&nbsp;
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_76_141)">
                            <path d="M6 10L12 16L18 10H6Z" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_76_141">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}

                >
                    <MenuItem onClick={handleClose}>
                        <Link className="menuitem" to={`/profile?session=${user._id}`}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_238_12)">
                                    <path d="M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14C15.0609 15 16.0783 15.4214 16.8284 16.1716C17.5786 16.9217 18 17.9391 18 19V21" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_238_12">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="menu-item">Profile</div>
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <button className="menuitem border-0 bg-transparent" onClick={SignOut}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_238_20)">
                                    <path d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 12H21L18 9" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18 15L21 12" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_238_20">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="menu-item">Logout</div>
                        </button>
                    </MenuItem>
                </Menu>
            </ul>
        </nav>
    );
}

export default Navbar;

function ResetPassword() {
    return (
        <div className="authentication">
            <div className="left-box">
                <div className="form-box">
                    <div className="auth-head">
                        <h3 className="mb-3">Reset your password</h3>
                        <p>To reset your password, enter your email below and submit. An email will be sent to you with instructions about how to complete the process.</p>
                    </div>
                    <form autoComplete="Off" className="auth-form" method="POST">
                        <div className="form-group">
                            <label>Email or username</label>
                            <input
                                type="text"
                                id="inputPassword5"
                                className="mb-3"
                                aria-describedby="passwordHelpBlock"
                                placeholder='example@gmail.com'
                            />
                        </div>
                        <button type="submit" className="form-submit">Reset Password</button>
                    </form>
                </div>
            </div>
            <div className="right-box">
                <img src="images/auth-signin.svg" alt="" />
            </div>
        </div>
    );
}

export default ResetPassword;
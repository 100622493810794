import axios from "axios";

export const postApi = async (url, values, headers = null) => {
    let postData;
    if (headers === null) {
        await axios.post(process.env.REACT_APP_BASE_URL + url, values)
            .then((res) => {
                postData = { status: 200, data: res.data.data, message: res.data.message };
            })
            .catch((error) => {
                postData = { status: 404, data: error.response.data.message };
            })
    } else {
        await axios.post(process.env.REACT_APP_BASE_URL + url, values, {
            headers: {
                'Content-Type': headers
            }
        })
            .then((res) => {
                postData = { status: 200, data: res.data.data, message: res.data.message };
            })
            .catch((error) => {
                postData = { status: 404, data: error.response.data.message };
            })
    }
    return postData;
};

export const getApi = async (url, headers = null) => {
    let getData;
    if (headers === null) {
        await axios.get(process.env.REACT_APP_BASE_URL + url).then((res) => {
            getData = { status: 200, data: res.data.data, message: res.data.message, dataCount: res.data.count };
        }).catch((error) => {
            getData = { status: 404, data: error.response.data.message };
        })
    } else {
        await axios.get(process.env.REACT_APP_BASE_URL + url, {
            headers: {
                'x-access-token': headers
            }
        }).then((res) => {
            getData = { status: 200, data: res.data.data, message: res.data.message, dataCount: res.data.count };
        }).catch((error) => {
            getData = { status: 404, data: error.response.data.message };
        })
    }
    return getData;
}
import React from 'react'
import { Link } from "react-router-dom";

function PageUnauthorized() {
    return (
        <div className="section position-relative">
            <div className='page-not-found'>
                <img src='/images/403Forbidden.svg' />
                <div className="page">You don't have permission to access this resource</div>
                <Link className="theme-btn-primary" to={'dashboard'} >Back to Home</Link>
            </div>
        </div>
    )
}

export default PageUnauthorized
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';
import { getApi, postApi } from '../../helpers/api';

function MyApp() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const userPermission = JSON.parse(user.permission);
    const navigate = useNavigate();
    const [isLoding, setIsLoding] = useState(true);
    const [serachRes, setserachRes] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [appData, setAppData] = useState([]);

    const notifyError = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    useEffect(() => {
        getAllApp(currentPage);
    }, [serachRes, currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    async function getAllApp(page) {
        !isLoding && setIsLoding(true);
        let appUrl = (serachRes && page) ? `apps?search=${serachRes}&pageCount=${page}` : `apps?pageCount=${page}`
        await getApi(appUrl, localStorage.getItem(process.env.REACT_APP_SECRET))
            .then(async (response) => {
                let newData = { ...response.data };
                if (response.status === 200) {
                    setAppData(newData.apps);
                    setDataCount(newData.appsCount);
                }
                setIsLoding(false);
            }).catch((err) => {
                if (err.response?.status === 403) {
                    dispatch({ type: 'LOGOUT', payload: null });
                    localStorage.removeItem(process.env.REACT_APP_SECRET);
                    navigate("/");
                }
            });
    }

    const editApp = (id) => {
        navigate(`/add-app/` + id);
    }

    const viewSource = (pkgname) => {
        navigate(`/app-response?packagename=${pkgname}`);
    }

    const deleteApp = async (id) => {
        await getApi(`delete-app?app=${id}`)
            .then((res) => {
                if (res.status === 200) {
                    getAllApp();
                    notifyError(res.message);
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    const toggleSwitch = async (id, currentStatus) => {
        let data = { "success": currentStatus };
        console.log(data);
        await postApi(`status-app?status=${id}`, data)
            .then((response) => {
                if (response.status === 200) {
                    const appStatus = appData.map(status => {
                        if (status.id === id) {
                            status.success = currentStatus;
                        }
                        return status;
                    })
                    setAppData(appStatus);
                    if(currentStatus) {
                        notifySuccess(response.message);
                    } else {
                        notifyError(response.message);
                    }
                }
            })
    };

    return (
        <>
            <ToastContainer />
            <div className='content-header'>
                <p className='breadcrumb'>
                    <Link to={'/dashboard'} className='back-link'>Dashboard</Link>/
                    <span className='active'>All Apps</span>
                </p>
                <div className='content-header-right'>
                    <div className='content-header-search'>
                        <span className='search-icon'>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M20.9999 21.4999L16.6499 17.1499" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        <input type="search" id="" className='' value={serachRes} placeholder='App name searching...' onChange={(search) => setserachRes(search.target.value)} />
                    </div>
                    {(user.role_name === "admin" || userPermission.app_c) && <Link className='theme-btn-primary' to={'/add-app'} key="add-user-link">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_86_9054)">
                                <path d="M8 2.5C12.8 2.5 14 3.7 14 8.5C14 13.3 12.8 14.5 8 14.5C3.2 14.5 2 13.3 2 8.5C2 3.7 3.2 2.5 8 2.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10 8.5H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 6.5V10.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_86_9054">
                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                        &nbsp;&nbsp;Add Application
                    </Link>}
                </div>
            </div>
            <table className='table-list apps-table'>
                <thead>
                    <tr>
                        <th className='list_id'>#</th>
                        <th className='table-image'>Icons</th>
                        <th>App name</th>
                        <th>Package name</th>
                        <th>Account Name</th>
                        <th className='table-status'>Status</th>
                        <th className='table-action'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoding ? (appData[0] !== null && appData.length) ? appData.map((appval, appkey) => {
                        return (
                            <tr key={appkey}>
                                <td className='text-center'>{appval.id}</td>
                                <td>
                                    {appval.app_image ? 
                                    <div className='app-logo'>
                                        <img src={`${process.env.REACT_APP_BASE_URL}uploads/appimages/${appval?.app_image}`} width={'100%'} />
                                    </div> : 
                                    <div className='app-text-logo'>{appval.app_name?.charAt(0)}</div>}
                                </td>
                                <td>{appval.app_name}</td>
                                <td>{appval.packagename}</td>
                                <td>{appval.accountname}</td>
                                {/* <td className='text-center'>
                                        <span className={`table-status-badge ${appval.status ? 'theme-success theme-text-success' : 'theme-danger theme-text-danager'}`}>{appval.status ? 'Publish' : 'Not Publish'}</span>
                                    </td> */}
                                <td>
                                    <div className="button-app" style={{ float: "none", margin: "0px 26px" }}>
                                        <div className="button r" id="button-1">
                                            <input type="checkbox" className="checkbox" checked={appval.success} onChange={(status) => toggleSwitch(appval.id, status.target.checked)} />
                                            <div className="knobs"></div>
                                            <div className="layer"></div>
                                        </div>
                                    </div>
                                </td>
                                <td className='text-center table-actions-menu'>
                                    <NavDropdown title="Action">
                                        {(user.role_name === "admin" || userPermission.app_e) && <NavDropdown.Item onClick={() => editApp(appval.id)}>
                                            <div className='action-btn'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_80_7131)">
                                                        <path d="M4 19.9998H8L18.5 9.49981C18.7626 9.23717 18.971 8.92537 19.1131 8.58221C19.2553 8.23905 19.3284 7.87125 19.3284 7.49981C19.3284 7.12838 19.2553 6.76058 19.1131 6.41742C18.971 6.07426 18.7626 5.76246 18.5 5.49981C18.2374 5.23717 17.9256 5.02883 17.5824 4.88669C17.2392 4.74455 16.8714 4.67139 16.5 4.67139C16.1286 4.67139 15.7608 4.74455 15.4176 4.88669C15.0744 5.02883 14.7626 5.23717 14.5 5.49981L4 15.9998V19.9998Z" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.5 6.5L17.5 10.5" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M16 19H22" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_80_7131">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <div className='action-text'>
                                                    Edit
                                                </div>
                                            </div>
                                        </NavDropdown.Item>}
                                        <NavDropdown.Item onClick={() => viewSource(appval.packagename)}>
                                            <div className='action-btn'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_203_2)">
                                                        <path d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10 13L9 15L10 17" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14 13L15 15L14 17" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_203_2">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <div className='action-text'>
                                                    View Source
                                                </div>
                                            </div>
                                        </NavDropdown.Item>
                                        {(user.role_name === "admin" || userPermission.app_d) && <NavDropdown.Item onClick={() => deleteApp(appval.id)}>
                                            <div className='action-btn' >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_146_525)">
                                                        <path d="M4 7H20" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10 11V17" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14 11V17" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7" stroke="#575A74" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_146_525">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <div className='action-text'>
                                                    Delete
                                                </div>
                                            </div>
                                        </NavDropdown.Item>}
                                    </NavDropdown>
                                </td>
                            </tr>
                        )
                    }) : 
                    <tr>
                        <td colSpan={6} className='text-center theme-text-danager'>Not Found</td>
                    </tr> : 
                    <tr>
                        <td colSpan={6} className='text-center theme-text-danager position-relative'><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></td>
                    </tr>}
                </tbody>
            </table>
            {dataCount > 10 && <div className='footer-pagination'>
                <ul className='paginations d-flex justify-content-end gap-1'>
                    {
                        Array.apply(null, Array(Math.ceil(dataCount / 10))).map((val, key) => {
                            return <li key={key} className={`pages-item ${currentPage === (key + 1) ? 'active' : ''}`} onClick={() => handlePageChange(key + 1)}>{key + 1}</li>
                        })
                    }
                </ul>
            </div>}
        </>
    );
}

export default MyApp;

import { React } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

function SideBar({ userData }) {
    const user = useSelector((state) => state.user.user);
    const currentPath = useLocation().pathname.split('/');
    const activeTab = '#2281E3';
    const deactiveTab = '#575A74';
    const permission = JSON.parse(user.permission);
    return (
        <>
            {<div className='sidebar'>
                <div className='brand-link'>
                    <Link path='/'>App Logo</Link>
                </div>
                <div className='sidemenus'>
                    <p className='menu-title'>General</p>
                    <ul className='sidemenu'>
                        {(user.role_name === 'admin' || permission?.home) && <li>
                            <Link to='/dashboard' className={`menuitem ${currentPath.includes('dashboard') ? activeTab : deactiveTab}`}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_71_44)">
                                        <path d="M5 12H3L12 3L21 12H19" stroke={currentPath.includes('dashboard') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5 12V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V12" stroke={currentPath.includes('dashboard') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10 12H14V16H10V12Z" stroke={currentPath.includes('dashboard') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_71_44">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className={`menu-item ${currentPath.includes('dashboard') ? 'active' : ''}`}>Dashboard</div>
                            </Link>
                        </li>}
                        {(user.role_name === 'admin' || permission?.app_r) && <li>
                            <Link to={'/apps'} className={`menuitem ${(currentPath.includes('apps') || currentPath.includes('add-app')) ? activeTab : deactiveTab}`}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_134_448)">
                                        <path d="M4 4H10V10H4V4Z" stroke={(currentPath.includes('apps') || currentPath.includes('add-app')) ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14 4H20V10H14V4Z" stroke={(currentPath.includes('apps') || currentPath.includes('add-app')) ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4 14H10V20H4V14Z" stroke={(currentPath.includes('apps') || currentPath.includes('add-app')) ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14 17C14 17.7956 14.3161 18.5587 14.8787 19.1213C15.4413 19.6839 16.2044 20 17 20C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17C20 16.2044 19.6839 15.4413 19.1213 14.8787C18.5587 14.3161 17.7956 14 17 14C16.2044 14 15.4413 14.3161 14.8787 14.8787C14.3161 15.4413 14 16.2044 14 17Z" stroke={(currentPath.includes('apps') || currentPath.includes('add-app')) ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_134_448">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className={`menu-item ${(currentPath.includes('apps') || currentPath.includes('add-app')) ? 'active' : ''}`}>All Apps</div>
                            </Link>
                        </li>}
                        {(user.role_name === 'admin') && <>
                            <li>
                                <Link to='/employee' className='menuitem'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_134_7)">
                                            <path d="M5 7C5 8.06087 5.42143 9.07828 6.17157 9.82843C6.92172 10.5786 7.93913 11 9 11C10.0609 11 11.0783 10.5786 11.8284 9.82843C12.5786 9.07828 13 8.06087 13 7C13 5.93913 12.5786 4.92172 11.8284 4.17157C11.0783 3.42143 10.0609 3 9 3C7.93913 3 6.92172 3.42143 6.17157 4.17157C5.42143 4.92172 5 5.93913 5 7Z" stroke={(currentPath.includes('employee') || currentPath.includes('employee-profile') || currentPath.includes('attendance') || currentPath.includes('add-employee')) ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 21V19C3 17.9391 3.42143 16.9217 4.17157 16.1716C4.92172 15.4214 5.93913 15 7 15H11C12.0609 15 13.0783 15.4214 13.8284 16.1716C14.5786 16.9217 15 17.9391 15 19V21" stroke={(currentPath.includes('employee') || currentPath.includes('employee-profile') || currentPath.includes('attendance') || currentPath.includes('add-employee')) ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke={(currentPath.includes('employee') || currentPath.includes('employee-profile') || currentPath.includes('attendance') || currentPath.includes('add-employee')) ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M21 20.9999V18.9999C20.9949 18.1171 20.6979 17.2607 20.1553 16.5643C19.6126 15.8679 18.8548 15.3706 18 15.1499" stroke={(currentPath.includes('employee') || currentPath.includes('employee-profile') || currentPath.includes('attendance') || currentPath.includes('add-employee')) ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_134_7">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div className={`menu-item ${(currentPath.includes('employee') || currentPath.includes('employee-profile') || currentPath.includes('attendance') || currentPath.includes('add-employee')) ? 'active' : ''}`}>Employee</div>
                                </Link>
                            </li>
                            <li>
                                <Link to='/projects' className='menuitem'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke={currentPath.includes('projects') ? activeTab : deactiveTab} fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" /><path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" /><path d="M15 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                                    <div className={`menu-item ${currentPath.includes('projects') ? 'active' : ''}`}>Projects</div>
                                </Link>
                            </li>
                            <li>
                                <Link to='/add-test-data/1' className='menuitem'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_157_572)">
                                            <path d="M5 5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5Z" stroke={currentPath.includes('add-test-data') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9 7H15" stroke={currentPath.includes('add-test-data') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9 11H15" stroke={currentPath.includes('add-test-data') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9 15H13" stroke={currentPath.includes('add-test-data') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_157_572">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div className={`menu-item ${currentPath.includes('add-test-data') ? 'active' : ''}`}>Test Data</div>
                                </Link>
                            </li>
                            <li>
                                <Link to='/settings' className='menuitem'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_134_13)">
                                            <path d="M10.325 4.317C10.751 2.561 13.249 2.561 13.675 4.317C13.7389 4.5808 13.8642 4.82578 14.0407 5.032C14.2172 5.23822 14.4399 5.39985 14.6907 5.50375C14.9414 5.60764 15.2132 5.65085 15.4838 5.62987C15.7544 5.60889 16.0162 5.5243 16.248 5.383C17.791 4.443 19.558 6.209 18.618 7.753C18.4769 7.98466 18.3924 8.24634 18.3715 8.51677C18.3506 8.78721 18.3938 9.05877 18.4975 9.30938C18.6013 9.55999 18.7627 9.78258 18.9687 9.95905C19.1747 10.1355 19.4194 10.2609 19.683 10.325C21.439 10.751 21.439 13.249 19.683 13.675C19.4192 13.7389 19.1742 13.8642 18.968 14.0407C18.7618 14.2172 18.6001 14.4399 18.4963 14.6907C18.3924 14.9414 18.3491 15.2132 18.3701 15.4838C18.3911 15.7544 18.4757 16.0162 18.617 16.248C19.557 17.791 17.791 19.558 16.247 18.618C16.0153 18.4769 15.7537 18.3924 15.4832 18.3715C15.2128 18.3506 14.9412 18.3938 14.6906 18.4975C14.44 18.6013 14.2174 18.7627 14.0409 18.9687C13.8645 19.1747 13.7391 19.4194 13.675 19.683C13.249 21.439 10.751 21.439 10.325 19.683C10.2611 19.4192 10.1358 19.1742 9.95929 18.968C9.7828 18.7618 9.56011 18.6001 9.30935 18.4963C9.05859 18.3924 8.78683 18.3491 8.51621 18.3701C8.24559 18.3911 7.98375 18.4757 7.752 18.617C6.209 19.557 4.442 17.791 5.382 16.247C5.5231 16.0153 5.60755 15.7537 5.62848 15.4832C5.64942 15.2128 5.60624 14.9412 5.50247 14.6906C5.3987 14.44 5.23726 14.2174 5.03127 14.0409C4.82529 13.8645 4.58056 13.7391 4.317 13.675C2.561 13.249 2.561 10.751 4.317 10.325C4.5808 10.2611 4.82578 10.1358 5.032 9.95929C5.23822 9.7828 5.39985 9.56011 5.50375 9.30935C5.60764 9.05859 5.65085 8.78683 5.62987 8.51621C5.60889 8.24559 5.5243 7.98375 5.383 7.752C4.443 6.209 6.209 4.442 7.753 5.382C8.753 5.99 10.049 5.452 10.325 4.317Z" stroke={currentPath.includes('settings') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z" stroke={currentPath.includes('settings') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_134_13">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div className={`menu-item ${currentPath.includes('settings') ? 'active' : ''}`}>Settings</div>
                                </Link>
                            </li>
                        </>}
                        {(user.role_name !== 'admin') && <li>
                            <Link to={`/profile?session=${user?._id}`} className={`menuitem`}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_238_12)">
                                        <path d="M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z" stroke={currentPath.includes('profile') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14C15.0609 15 16.0783 15.4214 16.8284 16.1716C17.5786 16.9217 18 17.9391 18 19V21" stroke={currentPath.includes('profile') ? activeTab : deactiveTab} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_238_12">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className={`menu-item ${currentPath.includes('profile') ? 'active' : ''}`}>Profile</div>
                            </Link>
                        </li>}
                    </ul>
                </div>
            </div>}
        </>
    )
}

export default SideBar; 
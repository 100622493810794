import { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from "formik";
import * as yup from "yup";
import { Modal } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { getApi, postApi } from "../../helpers/api";
import './project.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const Profile = () => {
    const [IsPending, setPending] = useState(false);
    //  ---- Projetc Data useState()
    const [serachRes, setserachRes] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [projectData, setProjectData] = useState([]);
    const [category, setCategory] = useState();
    // ----
    // ---- Create Project Form Data --
    const animatedComponents = makeAnimated();
    const [show, setShow] = useState(false);
    const [isLoding, setIsLoding] = useState(true);
    const [projectCategory, setProjectCategory] = useState([]);
    const [tlData, setTlData] = useState('');
    const [tmData, setTmData] = useState('');
    const [empArray, setEmpArray] = useState([]);
    const [uploadEmployee, setUploadEmployee] = useState('./images/default-image.png');
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
    // ----

    const successNotify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const errorNotify = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "colored",
    });

    useEffect(() => {
        getAllProject(currentPage);
    }, [currentPage, serachRes, category]);

    useEffect(() => {
        getAllProject(currentPage);
        getAllProjectCategory();
        getAllEmp();
    }, []);

    async function getAllProject(page) {
        !isLoding && setIsLoding(true);
        let ProjectUrl = (serachRes && page) ? `project?search=${serachRes}&pageCount=${page}` 
            : (category && page) ? `project?category=${category}&pageCount=${page}`
            : `project?pageCount=${page}`

        await getApi(ProjectUrl, localStorage.getItem(process.env.REACT_APP_SECRET))
            .then((res) => {
                let newData = [...res.data];
                setProjectData(newData);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    async function getAllProjectCategory() {
        await getApi('project-category')
            .then((res) => {
                setProjectCategory(res.data);
            })
    }

    async function getAllEmp() {
        let emptyArray = [];
        await getApi('my-employee')
            .then((res) => {
                let newEmpArray = [...empArray];
                res.data?.map((val, key) => {
                    newEmpArray.push({ value: val.id, label: val.fullname })
                })
                setEmpArray(newEmpArray);
            })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const addProjectModel = () => {
        setShow(true)
    }

    const fileChangeHandler = (event) => {
        setFieldValue("project_logo", event.target.files[0]);
        if (event.target.files && event.target.files.length) {
            setUploadEmployee(URL.createObjectURL(event.target.files[0]));
        }
    }

    async function closeMenu() {
        setShow(false);
        setUploadEmployee("./images/default-image.png");
        resetForm();
    }

    const [initialValues, setInitialValues] = useState({
        "project_name": '',
        "category_id": '',
        "description": '',
        "start_date": '',
        "end_date": '',
        "tl": "",
        "tm": "",
        "project_logo": ""
    })

    const validationSchema = yup.object({
        project_name: yup.string()
            .required("Project is required."),
        category_id: yup.string()
            .required("Category is required."),
        description: yup.string()
            .required("Project Description is required."),
        start_date: yup.string()
            .required("Start Date is required."),
        project_logo: yup
            .mixed()
            .test(
                "size",
                "Upload less than 1 MB file.",
                (value) => {
                    if (initialValues.project_logo) {
                        return true
                    } else if (value && value.size >= 1048576) {
                        return false
                    }
                    return true
                }
            )
            .test(
                "type",
                "Upload only .jpg, .jpeg, .png",
                (value) => {
                    if (initialValues.project_logo) {
                        return true
                    } else if (value && !SUPPORTED_FORMATS.includes(value.type)) {
                        return false
                    }
                    return true
                }
            )
            .nullable(true),
    })

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        resetForm,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            let newValues = { ...values }
            let tlArray = []
            let tmArray = []
            if (newValues.project_logo === "") {
                delete newValues.project_logo
            }
            if (tlData !== "") {
                tlData.map((val, key) => {
                    tlArray.push(val.value);
                });
                newValues.tl = tlArray.toString()
            }
            if (tmData !== "") {
                tmData.map((val, key) => {
                    tmArray.push(val.value);
                });
                newValues.tm = tmArray.toString()
            }
            setPending(true);
            await postApi('add-project', newValues, 'multipart/form-data').then((res) => {
                let result = { ...res };
                if (result.status === 200) {
                    getAllProject(currentPage);
                    setPending(false);
                    successNotify(result.message);
                    closeMenu();
                } else {
                    setPending(false);
                    errorNotify("Oops, Something went wrong.");
                    errors.project = result.data;
                }
            })
        }
    });

    return (
        <>
            <ToastContainer />
            <div className='content-header'>
                <p className='breadcrumb'>
                    <Link to={'/dashboard'} className='back-link'>Dashboard</Link>/
                    <span className='active'>All Projects</span>
                </p>
                <div className='content-header-right'>
                    <div className='content-header-search'>
                        <span className='search-icon'>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M20.9999 21.4999L16.6499 17.1499" stroke="#575A74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        <input type="search" value={serachRes} placeholder='Project name searching...' onChange={(search) => setserachRes(search.target.value)} />
                    </div>
                    <select onChange={(category) => setCategory(category.target.value)}>
                        <option value={""}>Project Category</option>
                        {
                            projectCategory?.map((val, key) => {
                                return <option value={val.id}>{val.category_name}</option>
                            })
                        }
                    </select>
                    <button className='theme-btn-primary' onClick={addProjectModel}>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_86_9054)">
                                <path d="M8 2.5C12.8 2.5 14 3.7 14 8.5C14 13.3 12.8 14.5 8 14.5C3.2 14.5 2 13.3 2 8.5C2 3.7 3.2 2.5 8 2.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10 8.5H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 6.5V10.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_86_9054">
                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                        &nbsp;&nbsp;Create Project
                    </button>
                </div>
            </div>
            <table className="table-list">
                <thead>
                    <tr>
                        <td>id</td>
                        <td>Project</td>
                        <td>Category</td>
                        <td>Team Leader</td>
                        <td>Team Member</td>
                        <td>Status</td>
                    </tr>
                </thead>
                <tbody>
                    {projectData?.length ? projectData.map((val, key) => {
                        return <tr>
                            <td>{val.id}</td>
                            <td>{val.project_name}</td>
                            <td>{val.project_category.category_name}</td>
                            <td>{val.tl}</td>
                            <td>{val.tm}</td>
                            <td>Active</td>
                        </tr>
                    }) : <tr>
                        <td colSpan={5} className="text-center theme-text-danager">Not Found.</td>
                    </tr>}
                </tbody>
            </table>

            <Modal
                size="lg"
                show={show}
                onHide={closeMenu}
                aria-labelledby="employee-added"
                className='employee-model'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="employee-added">
                        Create Project
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='employee-information'>
                        <form onSubmit={handleSubmit} autoComplete="off" encType='multipart/form-data'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    {errors.project_logo && touched.project_logo ? (
                                        <span className="required-lable mt-2 d-block text-center">{errors.project_logo} </span>
                                    ) : null}
                                    <div className='employee-upload-profile mt-4'>
                                        <img src={uploadEmployee} alt='employee upload.' />
                                        <div className='image-upload-btn'>
                                            <label htmlFor='image-upload'>Upload</label>
                                            <input type='file' name='project_logo' className='d-none' id='image-upload' onChange={(e) => fileChangeHandler(e)} accept="image/png, image/jpeg" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mt-0">
                                        <label>Project<span className="required-lable">*</span></label>
                                        <input type="text" className="form-control" placeholder="Project" name="project_name" onChange={handleChange}
                                            onBlur={handleBlur} value={values.project_name} />
                                        {errors.project_name && touched.project_name ? (
                                            <span className="required-lable">{errors.project_name} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mt-0">
                                        <label>Category<span className="required-lable">*</span></label>
                                        <select name='category_id' onChange={(e) => setFieldValue('category_id', e.target.value)} onBlur={handleBlur}>
                                            <option value={''}>Select project category</option>
                                        {
                                            projectCategory?.map((val, key) => {
                                                return <option value={val.id}>{val.category_name}</option>
                                            })
                                        }
                                        </select>
                                        {errors.category_id && touched.category_id ? (
                                            <span className="required-lable">{errors.category_id}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mt-0">
                                        <label>Start Date<span className="required-lable">*</span></label>
                                        <input type="date" className="form-control" placeholder="Start Date" name="start_date" onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.start_date} />
                                        {errors.start_date && touched.start_date ? (
                                            <span className="required-lable">{errors.start_date} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mt-0">
                                        <label>End Date</label>
                                        <input type="date" className="form-control" placeholder="End Date" name="end_date" onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.end_date} />
                                        {errors.end_date && touched.end_date ? (
                                            <span className="required-lable">{errors.end_date} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-group m-0">
                                        <label>Add Project Leader</label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={empArray}
                                            onChange={e => setTlData(e)}
                                        />
                                        {errors.tl && touched.tl ? (
                                            <span className="required-lable">{errors.tl} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label>Add Project Member</label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={empArray}
                                            onChange={e => setTmData(e)}
                                        />
                                        {errors.tm && touched.tm ? (
                                            <span className="required-lable">{errors.tm} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-group mt-0">
                                        <label>Description<span className="required-lable">*</span></label>
                                        <textarea className="form-control" placeholder="Project Description..." name="description" rows={3} onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description} />
                                        {errors.description && touched.description ? (
                                            <span className="required-lable">{errors.description} </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <button type="submit" className='theme-btn-primary d-block m-auto mb-4'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Profile;
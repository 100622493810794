import React from 'react'
import { Link } from "react-router-dom";

function PageNotFound() {
    return (
        <div className="section position-relative">
            <div className='page-not-found'>
                <img src='/icons/404-Error.svg' />
                <div className="page">Ooops!!! The page you are looking for is not found</div>
                <Link className="theme-btn-primary" to={'dashboard'} >Back to Home</Link>
            </div>
        </div>
    )
}

export default PageNotFound